import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthPipe } from '../../pipes/month.pipe';



@NgModule({
  declarations: [MonthPipe],
  exports: [MonthPipe]
})
export class SharedModule { }
