import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BillexService } from '@services/billex.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {

  user={password:'',email:''}

  showLoading = function() {
      Swal.fire({
          title: '',
          allowEscapeKey: false,
          allowOutsideClick: false,
          background: 'transparent',
          showConfirmButton: false,
          //timerProgressBar: true,
          //timer: 1000,
          didOpen: ()=>{
              Swal.showLoading();
          }

          // timer: 3000,
          // timerProgressBar: true
      });
  };

  hideLoading = function() {
      Swal.fire({
          title: '',
          allowEscapeKey: false,
          allowOutsideClick: false,
          background: 'transparent',
          showConfirmButton: false,
          timer: 1,
          didOpen: ()=>{
              Swal.hideLoading();
          }
      });
  };

  constructor(private billexService:BillexService, private authService: AuthService,private route:Router){}

  onLogin(){
    this.showLoading()

    if (this.user.email == '' || this.user.password == ''){
      return;
    }
    this.billexService.login(this.user).subscribe(
      resp=>{
        if(resp.success){
          localStorage.setItem('user', JSON.stringify(resp.usuario));
          localStorage.setItem('token', JSON.stringify(resp.token));
          localStorage.setItem('roles', JSON.stringify(resp.rols));

          this.authService.currentUser.next(resp.usuario);
          this.route.navigate([resp.rols[resp.rol]]);
          this.hideLoading();
        }
        else{
          alert(resp.data);
          this.user.password='';
          this.route.navigate(['/login']);

          this.hideLoading();
        }
      },err=>{
        alert('Acceso denegado');
        this.user.password='';
        this.route.navigate(['/login']);

        this.hideLoading();
      }
    );
  }

}