import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'NA'
  }
];

const navItemsOperaciones: INavData[] = [
  {
    title: true,
    name: 'Operaciones'
  },
  {
    name: 'Reporte Operaciones',
    url: '/operaciones/operation',
    icon: 'icon-drop'
  },
  {
    name: 'Cuentas Bancarias',
    url: '/operaciones/bankaccountlist',
    icon: 'icon-drop'
  },
  {
    name: 'Interbancarias',
    url: '/operaciones/interbancarias',
    icon: 'icon-drop',
    children: [
      {
        name: 'Tabla General',
        url: '/operaciones/interbancarias/tabla-comisiones',
        icon: 'icon-drop'
      },
      {
        name: 'Comisiones Cliente',
        url: '/operaciones/interbancarias/cliente-comisiones',
        icon: 'icon-drop'
      }
    ]
  },
  {
    name: 'Ejecutivos',
    url: '/operaciones/ejecutivos',
    icon: 'icon-user',
    children: [
      {
        name: 'Comisiones',
        url: '/operaciones/ejecutivos/comisiones',
        icon: 'icon-drop'
      }
    ]
  },
  {
    name: "Tiempos atención",
    url: '/operaciones',
    icon: 'icon-drop',
    children: [
      {
        name: 'Dashboard',
        url: '/operaciones/tiempos-atencion',
        icon: 'icon-speedometer'
      },
      {
        name: 'Reporte',
        url: '/operaciones/tiempos-atencion/reporte',
        icon: 'icon-chart'
      }
    ]
  }
];


const navItemsEjecutivos: INavData[] = [
  {
    title: true,
    name: 'Ejecutivos de Ventas'
  },
  {
    name: 'Dashboard',
    url: '/ejecutivos/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Prospectos',
    url: '/ejecutivos/prospectos',
    icon: 'icon-people',
    children: [
      {
        name: 'Registrar',
        url: '/ejecutivos/prospectos/registrar',
        icon: 'icon-plus'
      },
      {
        name: 'Seguimiento',
        url: '/ejecutivos/prospectos/seguimiento',
        icon: 'icon-user-following'
      },
      {
        name: 'Alertas',
        url: '/ejecutivos/prospectos/alertas',
        icon: 'icon-bell'
      }
    ]
  },
  {
    name: 'Cartera',
    url: '/ejecutivos/clientes',
    icon: 'icon-briefcase',
    children: [
      {
        name: 'Seguimiento',
        url: '/ejecutivos/clientes/seguimiento',
        icon: 'icon-user-following'
      },
      {
        name: 'Comisiones',
        url: '/ejecutivos/clientes/comisiones',
        icon: 'icon-credit-card'
      },
      {
        name: 'Alertas',
        url: '/ejecutivos/clientes/alertas',
        icon: 'icon-bell'
      },
      {
        name: 'Base Clientes PN',
        url: '/ejecutivos/clientes/naturales',
        icon: 'icon-drawer'
      },
      {
        name: 'Base Clientes PJ',
        url: '/ejecutivos/clientes/juridicas',
        icon: 'icon-drawer'
      },
      {
        name:'Reporte de Clientes',
        url: '/ejecutivos/clientes/reporte-clientes',
        icon: 'icon-user-following'
      }
    ]
  },
  {
    name: 'Tipo de Cambio',
    url: '/ejecutivos/tipodecambio',
    icon: 'icon-shuffle'
  }
];

const navItemsSupervisores: INavData[] = [
  {
    title: true,
    name: 'Supervisores Comerciales'
  },
  {
    name: 'Dashboard',
    url: '/supervisores/dashboard',
    icon: 'icon-speedometer'
  },
  {
    name: 'Ejecutivos',
    url: '/supervisores/ejecutivos',
    icon: 'icon-people',
    children: [
      {
        name: 'Lista',
        url: '/supervisores/ejecutivos/lista',
        icon: 'icon-list',
      },
    ]
  },
  {
    name: 'Prospectos',
    url: '/supervisores/prospectos',
    icon: 'icon-user-follow',
    children: [
      {
        name: 'Dashboard',
        url: '/supervisores/prospectos/dashboard',
        icon: 'icon-speedometer'
      },
      {
        name: 'Lista',
        url: '/supervisores/prospectos/lista',
        icon: 'icon-list'
      },
      {
        name: 'Reasignar',
        url: '/supervisores/prospectos/reasignar',
        icon: 'icon-magnifier-add'
      },
    ]
  },
  {
    name: 'Cartera',
    url: '/supervisores/clientes',
    icon: 'icon-briefcase',
    children: [
      {
        name: 'Dashboard',
        url: '/supervisores/cartera/dashboard',
        icon: 'icon-speedometer'
      },
      {
        name: 'Lista',
        url: '/supervisores/cartera/lista',
        icon: 'icon-list'
      },
      {
        name: 'Asignar',
        url: '/supervisores/cartera/asignar',
        icon: 'icon-magnifier-add'
      },
    ]
  },
  {
    name: 'Reportes',
    url: '/supervisores/reportes',
    icon: 'icon-pie-chart',
    children: [
      {
        name: 'Reporte de ventas',
        url: '/supervisores/reportes/ventas',
        icon: 'icon-chart'
      },
      {
        name:'Ops Mensual sin Fid',
        url: '/supervisores/reportes/op-mens-sf',
        icon: 'icon-chart'
      },
      {
        name:'Detalle Ops sin Fid',
        url: '/supervisores/reportes/detail-op-sf',
        icon: 'icon-chart'
      }
    ]
  }
];

const navItemsAdministrador: INavData[] = [
  {
    title: true,
    name: 'Administrador'
  },
  {
    name: 'Comisiones Especiales',
    url: '/administrador/comisionespecial',
    icon: 'icon-credit-card'
  },
  {
    name: 'Tipo de cambio',
    url: '/administrador/tipodecambio',
    icon: 'icon-shuffle'
  },
  {
    name: 'Cuentas Fideicomiso',
    url: '/administrador/cuentasfideicomiso',
    icon: 'icon-shield'
  },
  {
    name: 'Posts',
    url: '/administrador/posts',
    icon: 'icon-book-open',
    children: [
      {
        name: 'Lista',
        url: '/administrador/posts/lista',
        icon: 'icon-list'
      },
      {
        name: 'Nuevo',
        url: '/administrador/posts/nuevo',
        icon: 'icon-plus'
      },
    ]
  },
  {
    name: 'Clientes',
    url: '/administrador/clientes',
    icon: 'icon-user',
    children: [
      {
        name: 'Lista',
        url: '/administrador/clientes/lista',
        icon: 'icon-list'
      }
    ]
  },
  {
    name: 'Usuarios',
    url: '/administrador/usuarios',
    icon: 'icon-user',
    children: [
      {
        name: 'Lista',
        url: '/administrador/usuarios/lista',
        icon: 'icon-list'
      }
    ]
  },
  {
    name: 'Ejecutivos',
    url: '/administrador/ejecutivos',
    icon: 'icon-user',
    children: [
      {
        name: 'Lista',
        url: '/administrador/ejecutivos/lista',
        icon: 'icon-list'
      }
    ]
  }
];

export function conditionalNavItems(id) {
  switch (id) {
    case 0:
      return navItems;
      break;
    case 1:
      return navItemsOperaciones;
      break;
    case 2:
      return navItemsEjecutivos;
      break;
    case 3:
      return navItemsSupervisores;
      break;
    case 4:
      return navItemsAdministrador;
      break;
    default:
      console.log('Default');
      return navItems;
  }
}
