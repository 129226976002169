import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillexService {

  url:string;

  constructor(private _http:HttpClient) { this.url=environment.url; }

  login(user):Observable<any>{
    let params = JSON.stringify(user);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/auth/login`, params,{headers:headers});
  }

  getOperations():Observable<any>{
    return this._http.get(this.url+`/api/admin/operaciones`);
  }

  accountClient(op):Observable<any>{
    return this._http.get(this.url+`/api/admin/cuentasCliente/${op}`);
  }

  accountFideicomiso(op):Observable<any>{
    return this._http.get(this.url+`/api/admin/cuentasFideicomiso/${op}`);
  }

  updateAccount(obj,id):Observable<any>{
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/api/admin/operaciones/${id}`, params, { headers: headers });
  }

  updateAccountFide(obj,id):Observable<any>{
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/api/admin/opsfideicomiso/${id}`, params, { headers: headers });
  }

  updateStateOperation(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/api/admin/optopef/${id}`, params, { headers: headers });
  }

  modifyOperation(obj, id):Observable<any>{
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + `/api/admin/operaciones/changeoperation/${id}`, params, { headers: headers });
  }

  operationDetail(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/admin/operaciones/detalleoperacion/${id}`, { headers: headers });
  }

  getRegions():Observable<any>{
    return this._http.get(this.url+`/api/crm/regions`);
  }

  getSectors():Observable<any>{
    return this._http.get(this.url+`/api/crm/sectors`);
  }

  validateRuc(ruc):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/validaruc?ruc=${ruc}`, { headers: headers });
  }

  validateDNI(tipo, dni): Observable<any> {
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/validadni?tipo_documento=${ tipo }&nro_documento=${ dni }`, { headers: headers });
  }

  searchLeads(obj):Observable<any>{
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/listaprospectos`, params,{headers:headers});
  }

  registerLead(lead):Observable<any>{
    let params = JSON.stringify(lead);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/registrarprospecto`, params,{headers:headers});
  }

  leadDetail(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/detalleprospecto?prospecto_id=${id}`, { headers: headers });
  }

  contactDetail(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/detallecontacto?contact_id=${id}`, { headers: headers });
  }

  addContactData(contact):Observable<any>{
    let params = JSON.stringify(contact);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/datacontacto`, params,{headers:headers});
  }

  deleteContactData(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url+`/api/crm/ejecutivos/datacontacto?contactdata_id=${id}`,{headers:headers});
  }

  getLeadTracking(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/seguimientosprospecto?prospecto_id=${id}`, { headers: headers });
  }

  registerLeadTracking(track):Observable<any>{
    let params = JSON.stringify(track);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/seguimientosprospecto`, params,{headers:headers});
  }

  addNewContact(contact):Observable<any>{
    let params = JSON.stringify(contact);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/contactoprospecto`, params,{headers:headers});
  }

  updateContact(contact):Observable<any>{
    let params = JSON.stringify(contact);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.put(this.url+`/api/crm/ejecutivos/contactoprospecto`, params,{headers:headers});
  }

  deleteContact(id):Observable<any>{
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.delete(this.url+`/api/crm/ejecutivos/contactoprospecto?lead_contact_id=${id}`, {headers:headers});
  }

  getAlertasProspectos():Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/alertasprospectos`, { headers: headers });
  }

  updateLead(lead):Observable<any>{
    let params = JSON.stringify(lead);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/actualizaprospecto`, params,{headers:headers});
  }



  searchClients(obj):Observable<any>{
    let params = JSON.stringify(obj);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/listaclientes`, params,{headers:headers});
  }

  clientDetail(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/detallecliente?cliente_id=${id}`, { headers: headers });
  }

  clientChart(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/graficocliente?cliente_id=${id}`, { headers: headers });
  }

  getClientTracking(id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/seguimientoscliente?cliente_id=${id}`, { headers: headers });
  }

  registerClientTracking(track):Observable<any>{
    let params = JSON.stringify(track);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/seguimientoscliente`, params,{headers:headers});
  }

  registerComision(comision):Observable<any>{
    let params = JSON.stringify(comision);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.post(this.url+`/api/crm/ejecutivos/comisioncliente`, params, {headers:headers});
  }

  deleteComision(cliente_id):Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.put(this.url+`/api/crm/ejecutivos/comisioncliente?cliente_id=${cliente_id}`, params,{headers:headers});
  }

  updateContactClient(contact):Observable<any>{
    let params = JSON.stringify(contact);
    let headers = new HttpHeaders().set('Content-Type','application/json');
    return this._http.put(this.url+`/api/crm/ejecutivos/contactocliente`, params,{headers:headers});
  }

  getAlertasClientes():Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/alertasclientes`, { headers: headers });
  }

  getDashboardEjecutivos():Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/ejecutivos/dashboard`, { headers: headers });
  }

  getBankAccounts():Observable<any>{
    let params;
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/operaciones/cuentasbancarias`, { headers: headers });
  }

  getClientes(cliente): Observable<any> {
    const {
      contact_type,
      document_number,
      company_name
    } = cliente;

    let params = JSON.stringify([cliente]);
    return this._http.get(this.url + `/api/crm/admin/clientes/lista?company_name=${ company_name }&document_number=${ document_number }&contact_type=${ contact_type }`,);
  }

  getAprovedAccountsByClient(id: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + `/api/crm/admin/clientes/cuentas?cliente_id=${ id }`,{ headers: headers });
  }

  updateAprovedAccount(account: any): Observable<any> {
    let params = account;
    return this._http.put(this.url + "/api/crm/admin/clientes/cuentas", params);
  }

  getBanks(): Observable<any> {
    return this._http.get(this.url + '/api/registro/listabancos');
  }

  getUsers(id: string): Observable<any> {
    return this._http.get(this.url + `/api/crm/admin/clientes/usuarios?cliente_id=${ id }`);
  }

  getAccountDetail(id: string): Observable<any> {
    return this._http.get(this.url + `/api/crm/admin/clientes/cuentas/${ id }`);
  }

  getClientDetails(id: string): Observable<any> {
    return this._http.get(this.url + `/api/crm/admin/clientes/${ id }`);
  }

  getDepartamentos(): Observable<any> {
    return this._http.get(this.url + '/api/res/departamentos')
  }

  getProvincias(departamentoId: string): Observable<any> {
    return this._http.get(this.url + `/api/res/provincias?DepartamentoId=${departamentoId }`)
  }

  getDistritos(provinciaId: string): Observable<any> {
    return this._http.get(this.url + `/api/res/distritos?ProvinciaId=${ provinciaId }`)
  }

  updateClient(client: any): Observable<any> {
    return this._http.put(this.url + '/api/crm/admin/clientes', client);
  }

  getTableComissions(): Observable<any> {
    return this._http.get(this.url + '/api/crm/operaciones/tablageneral');
  }

  updateComissionInterbancario(new_comission: any): Observable<any> {
    return this._http.put(this.url + '/api/crm/operaciones/tablageneral', new_comission);
  }

  getClientsOperations(): Observable<any> {
    return this._http.get(this.url + '/api/crm/operaciones/clientes');
  }

  createComission(comission: any): Observable<any> {
    return this._http.post(this.url + '/api/crm/operaciones/comisionib', comission)
  }

  getActiveComissions(): Observable<any> {
    return this._http.get(this.url + '/api/crm/operaciones/comisionib');
  }

  deleteComissionOperations(id: any): Observable<any> {
    return this._http.delete(this.url + `/api/crm/operaciones/comisionib?id=${id}`)
  }

  getTipoCambioList(fecha: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/tchistorico?fecha=${ fecha }`)
  }

  getTipoCambioActivo(): Observable<any> {
    return this._http.get(this.url + '/api/crm/ejecutivos/tcactivo');
  }

  getDataComissionCharts(): Observable<any> {
    return this._http.get(this.url + '/api/crm/ejecutivos/comisionesclientes')
  }

  getOperationsComissionsList(parameters: any): Observable<any> {
    const { month, year } = parameters;
    
    return this._http.get(this.url + `/api/crm/ejecutivos/listaoperaciones?month=${ month }&year=${ year }`)
  }

  getBaseClientes(parameters: any): Observable<any> {
    const { type, company_name, documento_number } = parameters;

    return this._http.get(this.url + `/api/crm/ejecutivos/baseclientes?type=${ type }&company_name=${ company_name }&document_number=${ documento_number }`);
  }

  getProveedoresLiquidez(): Observable<any> {
    return this._http.get(this.url + '/api/crm/ejecutivos/listapl');
  }

  getProveedoresLiquidezSinFideicomiso(): Observable<any> {
    return this._http.get(this.url + '/api/crm/ejecutivos/proveedoressinfideicomiso');
  }

  getClientesNuevos(month: any = '', year: any = ''): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/clientes_nuevos?month=${month}&year=${year}`);
  }

  getReporteClientesMensual(): Observable<any>{
    return this._http.get(this.url + '/api/crm/ejecutivos/reporte_clientes_mensual');
  }

  registerTipoCambioEspecial(tipocambio: any): Observable<any> {
    return this._http.post(this.url + '/api/crm/ejecutivos/tcespecial', tipocambio)
  }

  getTipoCambioEspecialActivos(client_id: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/tcespecial?client_id=${client_id}`)
  }
  
  deleteTipoCambioEspecial(client_id: any): Observable<any> {
    return this._http.delete(this.url + `/api/crm/ejecutivos/tcespecial?client_id=${client_id}`)
  }

  getCotizacion(client_id: any, monto: any, tipo_operacion: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/cotizar?client_id=${ client_id }&monto=${ monto }&tipo_operacion=${ tipo_operacion }`)
  }

  getEjecutivosList(month: any, year: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/operaciones/ejecutivos?month=${ month }&year=${ year }`)
  }

  getOperationsDetail(executive_id: any, month: any, year: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/operaciones/listaoperaciones?executive_id=${ executive_id }&month=${ month }&year=${ year }`)
  }

  getClientAccounts(client_id: any, currency_id: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/cuentascliente?client_id=${ client_id }&currency_id=${ currency_id }`);
  }
  
  getEscrowAccounts(currency_id: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/cuentasfideicomiso?currency_id=${ currency_id }`);
  }

  createOperation(params: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/api/crm/ejecutivos/crearop`, params, { headers: headers })
  }

  getBancosEjecutivos() : Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/bancos`);
  }

  getListaTipoCuentas(): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/tipocuenta`);
  }

  getListaCuentasCliente(client_id: any): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/cuentabancaria?client_id=${client_id}`);
  }

  crearCuentaBancaria(params: any): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + `/api/crm/ejecutivos/cuentabancaria`, params, { headers: headers });
  }


  getMinAmountInterbancaria(divisa_id: number): Observable<any> {
    return this._http.get(`${this.url}/api/crm/ejecutivos/montominimo?divisa_id=${ divisa_id }`)
  }
  
  getCotizacionComisionInterbancaria(params: any): Observable<any> {
    return this._http.get(this.url + "/api/crm/ejecutivos/parametrositbc", {params: params})
  }

  getCuentasBancariasInterbancaria(params: any): Observable<any> {
    return this._http.get(this.url + "/api/crm/ejecutivos/cuentascliente", { params })
  }

  getCuentasFideicomisoInterbancaria(params: any): Observable<any> {
    return this._http.get(this.url + "/api/crm/ejecutivos/cuentasfideicomiso", { params });
  }

  crearOperacionInterbancaria(operation: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    })
    return this._http.post(this.url + `/api/crm/ejecutivos/crearopinterbancaria`, operation, {headers: headers})
  }

  postConfiguracionInterbancaria(params: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json"
    })

    return this._http.post(this.url + "/api/crm/ejecutivos/parametrosclienteitbc", params, {headers: headers})
  }

  getConfiguracionInterbancaria(params: any): Observable<any> {
    return this._http.get(this.url + "/api/crm/ejecutivos/parametrosclienteitbc", {params: params})
  }

  deleteConfiguracionInterbancaria(params: any): Observable<any> {
    return this._http.delete(this.url + "/api/crm/ejecutivos/parametrosclienteitbc", {params: params})
  }

  //Tiempos de atención

  getCurrentOperations(status: string): Observable<any> {
    return this._http.get(this.url + `/api/crm/operaciones/tiemposatencionops?status=${status}`)
  }

  getCurrentOperationsEjecutivos(status: string): Observable<any> {
    return this._http.get(this.url + `/api/crm/ejecutivos/tiemposatencionops?status=${status}&executives=true`);
  }

  getDataDashboardAtencion(month: number, year: number): Observable<any> {
    return this._http.get(this.url + `/api/crm/operaciones/dashboardtiemposatencion?month=${month}&year=${year}`);
  }

  getReporteTiemposAtencion(month: number, year: number): Observable<any> {
    return this._http.get(this.url + `/api/crm/operaciones/tiemposatencion?month=${month}&year=${year}`)
  }

}
