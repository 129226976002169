import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { IApiUserAuthenticated } from '../interfaces/api/iapi-auth-user.metadata';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser: BehaviorSubject<IApiUserAuthenticated>;
  public userRoles: BehaviorSubject<IApiUserAuthenticated>;
  public nameUserLS = 'user';

  constructor(
      private http: HttpClient,
      private router: Router
  ) {
      this.currentUser = new BehaviorSubject(
        JSON.parse(localStorage.getItem(this.nameUserLS))
      );
      this.userRoles = new BehaviorSubject(
        JSON.parse(localStorage.getItem('roles'))
      );
   }

  get getUser(): IApiUserAuthenticated {
    return this.currentUser.value;
  }

  get getRoles(): IApiUserAuthenticated {
    return this.userRoles.value;
  }

  logout() {
    localStorage.removeItem(this.nameUserLS);
    localStorage.removeItem('roles');
    localStorage.removeItem('token');

    this.currentUser.next(null);
    this.router.navigate(['/login'])
  }

}
