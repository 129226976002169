import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Billex'
    },
    children: [
      {
        path: 'operaciones',
        canActivate: [AuthGuard],
        data: {roles: ['operaciones']},
        loadChildren: () => import('./views/operations/operations.module').then(m => m.OperationsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'ejecutivos',
        canActivate: [AuthGuard],
        data: {roles: ['ejecutivos']},
        loadChildren: () => import('./views/ejecutivos/ejecutivos.module').then(m => m.EjecutivosModule)
      },
      {
        path: 'supervisores',
        canActivate: [AuthGuard],
        data: {roles: ['supervisores']},
        loadChildren: () => import('./views/supervisores/supervisores.module').then(m => m.SupervisoresModule)
      },
      {
        path: 'administrador',
        canActivate: [AuthGuard],
        data: {roles: ['administrador']},
        loadChildren: () => import('./views/administrador/administrador.module').then(m => m.AdministradorModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
