import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html'
})
export class OperationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }

}
