import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
    ){}

  canActivate(
    next: ActivatedRouteSnapshot,
    //state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    state: RouterStateSnapshot
    ): boolean {
      if(localStorage.getItem('roles') == null) return false;

      const userRoles = Object.values(this.authService.getRoles);

      let roles = next.data.roles as Array<string>;

      var hasPermision = false;

      userRoles.forEach(function(currentValue, index){
        if(currentValue == roles[0]) hasPermision = true;
      });

      if (hasPermision){
        return true;
      }

      this.router.navigate(['/login']);

      return false;
    }
  
}
