import { Component } from '@angular/core';
import { navItems, conditionalNavItems } from '../../_nav';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'app/services/auth.service';

import { filter } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})

export class DefaultLayoutComponent {

  public modulos;
  public usuario;


  //constructor(private router: Router ) {}

  constructor(
  private router: Router,
  private authService: AuthService,
  private location: Location
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event: NavigationEnd) => {
      this.assignNavItems(event.url);
    });
  }

  public sidebarMinimized = false;
  public navItems;


  ngOnInit(): void {
    //this.assignNavItems(this.router.url);
    this.modulos = Object.values(this.authService.getRoles);
    this.usuario = (this.authService.getUser?.Nombres || "") + " " + (this.authService.getUser?.Apellidos || "");
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  assignNavItems(url) {
    var path  = url;
    var size = (path.indexOf('/', 1) > -1) ? path.indexOf('/', 1) : path.length;
    var parent = path.substring(0, size);

    if( parent == '/operaciones'){
      this.navItems = conditionalNavItems(1);
    }else if( parent == '/ejecutivos'){
      this.navItems = conditionalNavItems(2);
    }else if( parent == '/supervisores'){
      this.navItems = conditionalNavItems(3);
    }else if( parent == '/administrador'){
      this.navItems = conditionalNavItems(4);
    }
    else {
      this.navItems = conditionalNavItems(0);
    }

  }

  back(): void {
    this.location.back();
  }

  logout(){
    this.authService.logout();
  }

}
